import React, { useContext } from "react";
import SectionHeading from "~components/SectionHeading";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

const Footer = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { locale } = appContext;
  const { device } = documentContext;

  let jobsEmail = ``;

  switch (locale.path) {
    case `en`:
      jobsEmail = `melbourne@paperstonescissors.com`;

      break;

    case `cn`:
      jobsEmail = `shanghai@paperstonescissors.com`;

      break;

    default:
      jobsEmail = `melbourne@paperstonescissors.com`;

      break;
  }

  return (
    <footer className="footer w-full relative bg-olive text-black">
      <div className="footer__inner grid pt-24">
        <SectionHeading
          grid="8"
          text="Follow us socially"
          whitespace="no-wrap"
          wrapped={false}
        />

        <ul className="footer__links grid-end-5 grid-start-19 sm:grid-end-22 sm:grid-start-2 -mt-2 xs:mt-8">
          <li className="f4">
            <a
              href="https://www.instagram.com/paper_stone_scissors/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link transition-opacity block py-2 xs:py-2"
            >
              Instagram
            </a>
          </li>

          <li className="f4">
            <a
              href="https://www.linkedin.com/company/paper-stone-scissors"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link transition-opacity block py-2 xs:py-2"
            >
              LinkedIn
            </a>
          </li>

          <li className="f4">
            <button
              type="button"
              className="footer__link transition-opacity block py-2 xs:py-2"
              onClick={() => {
                appContext.setWeChatActive(true);
              }}
            >
              WeChat
            </button>
          </li>

          <li className="f4">
            <a
              href={`mailto:${jobsEmail}`}
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link transition-opacity block py-2 xs:py-2"
            >
              Jobs
            </a>
          </li>
        </ul>

        <h3 className="footer__copyright grid-end-5 sm:grid-end-12 grid-start-20 sm:grid-start-13 flex justify-end mt-48 mb-12 xs:mb-5 xs:pb-1 b2">
          © Paper Stone Scissors
        </h3>
      </div>
    </footer>
  );
};

export default Footer;
