import React from "react";
import { PropTypes } from "prop-types";

const SectionHeading = ({
  alignment,
  color,
  grid,
  padding,
  small,
  start,
  style,
  text,
  whitespace,
  wrapped
}) => (
  <>
    {(wrapped && (
      <div
        className={`section-heading ${
          wrapped ? `grid` : ``
        } ${padding} whitespace-${whitespace}`}
      >
        <h3
          className={`grid-end-${grid} sm:grid-end-19 ${start} flex items-start p${
            alignment === `top` ? `t-8` : `b-8`
          } ${small ? `b2 feijoa-ot` : `b1`} text-${color}`}
          style={style}
        >
          <span className="mr-2">—</span>
          <span>{text}</span>
        </h3>
      </div>
    )) || (
      <h3
        className={`section-heading grid-end-${grid} sm:grid-end-19 ${start} flex items-start ${
          small ? `b2 feijoa-ot` : `b1`
        } text-${color} whitespace-${whitespace}`}
        style={style}
      >
        <span className="mr-2">—</span>
        <span>{text}</span>
      </h3>
    )}
  </>
);

SectionHeading.defaultProps = {
  alignment: `top`,
  color: `black`,
  grid: `18`,
  padding: `pt-12 xs:pt-0`,
  small: false,
  start: `grid-start-5 sm:grid-start-3`,
  style: {},
  text: `Default Heading`,
  whitespace: `normal`,
  wrapped: true
};

SectionHeading.propTypes = {
  alignment: PropTypes.string,
  color: PropTypes.string,
  grid: PropTypes.string,
  padding: PropTypes.string,
  small: PropTypes.bool,
  start: PropTypes.string,
  style: PropTypes.shape({}),
  text: PropTypes.string,
  whitespace: PropTypes.string,
  wrapped: PropTypes.bool
};

export default SectionHeading;
